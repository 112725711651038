//** React Imports
import { useState, useEffect } from "react"

import WhiteLabelJson from "../../whiteLabel/whiteLabel.json"

export const useStyle = () => {

  const [style, setStyle] = useState(
    WhiteLabelJson[window.location.hostname].css
  )

  return [style, setStyle]
}
