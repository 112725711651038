//** React Imports
import { useState, useEffect } from "react"

import WhiteLabelJson from "../../whiteLabel/whiteLabel.json"

export const useLogo = () => {
  const [logo, setLogo] = useState(
    WhiteLabelJson[window.location.hostname]["logo"]
  )

  return [logo, setLogo]
}
