import React from "react"

import WhiteLabelJson from "../whiteLabel/whiteLabel.json"

const init = () => {
  const favicon = document.getElementById("favicon")

  const siteTitle = document.getElementById("site-title")
  favicon.href = `favicon/${
    WhiteLabelJson[window.location.hostname]["favicon"]
  }`
  siteTitle.innerText = `${
    WhiteLabelJson[window.location.hostname]["title"]
  } Dashboard`
}

export default init
