// ** Logo
import { useLogo } from "../../../utility/hooks/useLogo"

import { useStyle } from "../../../utility/hooks/useStyle"

const SpinnerComponent = () => {
  const [logo, setLogo] = useLogo()
  const [style, setStyle] = useStyle()

  return (
    <>
      <link rel="stylesheet" type="text/css" href={`/styles/${style}`} />
      <div className="fallback-spinner vh-100">
        <img className="fallback-logo" src={`/logos/${logo}`} alt="logo" />
        <div className="loading">
          <div className="effect-1 effects"></div>
          <div className="effect-2 effects"></div>
          <div className="effect-3 effects"></div>
        </div>
      </div>
    </>
  )
}

export default SpinnerComponent
